import { CSSProperties } from 'react';

export const CheckmarkIcon = () => (
    <svg viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path
            d='M19.0607 6.43934C19.6464 7.02513 19.6464 7.97487 19.0607 8.56066L10.8107 16.8107C10.2249 17.3964 9.27513 17.3964 8.68934 16.8107L4.93934 13.0607C4.35355 12.4749 4.35355 11.5251 4.93934 10.9393C5.52513 10.3536 6.47487 10.3536 7.06066 10.9393L9.75 13.6287L16.9393 6.43934C17.5251 5.85355 18.4749 5.85355 19.0607 6.43934Z'
            fill='currentColor'
        />
    </svg>
);
export const IcCheckmarkCircle = ({ style }: { style?: CSSProperties | undefined }) => (
    <svg viewBox='0 0 24 24' style={{ fill: 'currentColor', ...style }}>
        <g clipPath='url(#checkmark_a)'>
            <g filter='url(#checkmark_b)'>
                <path
                    fill='url(#checkmark_c)'
                    d='M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z'
                />
            </g>
            <path
                fill='#fff'
                d='M12 3.818a8.182 8.182 0 1 0 0 16.363 8.182 8.182 0 0 0 0-16.363ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z'
            />
            <path
                fill='#fff'
                d='M15.707 9.293a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414L11 12.586l3.293-3.293a1 1 0 0 1 1.414 0Z'
            />
        </g>
        <defs>
            <linearGradient
                id='checkmark_c'
                x1='1.564'
                x2='22.434'
                y1='22.435'
                y2='1.565'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#22C55E' />
                <stop offset='1' stopColor='#16A34A' />
            </linearGradient>
            <clipPath id='checkmark_a'>
                <path fill='#fff' d='M0 0h24v24H0z' />
            </clipPath>
            <filter
                id='checkmark_b'
                width='24'
                height='24'
                x='0'
                y='1'
                colorInterpolationFilters='sRGB'
                filterUnits='userSpaceOnUse'
            >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
                <feOffset dy='1' />
                <feGaussianBlur stdDeviation='1' />
                <feComposite in2='hardAlpha' operator='out' />
                <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0' />
                <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1135_884' />
                <feBlend in='SourceGraphic' in2='effect1_dropShadow_1135_884' result='shape' />
            </filter>
        </defs>
    </svg>
);

export const ChevronRightIcon = ({ style }: { style?: CSSProperties | undefined }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' style={{ fill: 'currentColor', ...style }}>
        <path fill='currentColor' d='M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z' />
    </svg>
);
export const HomeIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z' />
    </svg>
);

export const CloseIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z'></path>
    </svg>
);
export const ArrowDownIcon = () => (
    <svg viewBox='0 0 24 24'>
        <path fill='currentColor' d='M12 4a1 1 0 0 1 1 1v14a1 1 0 1 1-2 0V5a1 1 0 0 1 1-1Z' />
        <path
            fill='currentColor'
            d='M4.293 11.293a1 1 0 0 1 1.414 0L12 17.586l6.293-6.293a1 1 0 0 1 1.414 1.414l-7 7a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 0-1.414Z'
        />
    </svg>
);
export const DropdownIcon = ({ style }: { style?: CSSProperties | undefined }) => (
    <svg
        className='dropdown'
        xmlns='http://www.w3.org/2000/svg'
        height='1em'
        width='1em'
        viewBox='0 0 24 24'
        stroke='currentColor'
        fill='currentColor'
        focusable='false'
        aria-hidden='true'
        style={{ fill: 'currentColor', ...style }}
    >
        <path d='M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z'></path>
    </svg>
);
export const FeedbackErrorIcon = ({ style }: { style?: CSSProperties | undefined }) => (
    <svg width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor', ...style }}>
        <path
            fill='currentColor'
            fillRule='evenodd'
            d='M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm14.707-3.707a1 1 0 0 1 0 1.414L13.414 12l2.293 2.293a1 1 0 0 1-1.414 1.414L12 13.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L10.586 12 8.293 9.707a1 1 0 0 1 1.414-1.414L12 10.586l2.293-2.293a1 1 0 0 1 1.414 0Z'
            clipRule='evenodd'
        />
    </svg>
);
export const HelpIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z'></path>
        <path d='M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z'></path>
    </svg>
);
export const ImageIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
        <path
            fill='currentColor'
            d='M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5ZM2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5Z'
        />
        <path
            fill='currentColor'
            d='M9 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM6 9a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM14.379 11.207a3 3 0 0 1 4.242 0l3.086 3.086a1 1 0 0 1-1.414 1.414l-3.086-3.086a1 1 0 0 0-1.414 0l-9.086 9.086a1 1 0 1 1-1.414-1.414l9.086-9.086Z'
        />
    </svg>
);
export const InboxIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z'></path>
    </svg>
);
export const InfoIcon = ({ style }: { style?: CSSProperties | undefined }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        style={{ fill: 'currentColor', ...style }}
    >
        <path d='M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z'></path>
        <path d='M11 11h2v6h-2zm0-4h2v2h-2z'></path>
    </svg>
);
export const LoginIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='m10.998 16 5-4-5-4v3h-9v2h9z'></path>
        <path d='M12.999 2.999a8.938 8.938 0 0 0-6.364 2.637L8.049 7.05c1.322-1.322 3.08-2.051 4.95-2.051s3.628.729 4.95 2.051S20 10.13 20 12s-.729 3.628-2.051 4.95-3.08 2.051-4.95 2.051-3.628-.729-4.95-2.051l-1.414 1.414c1.699 1.7 3.959 2.637 6.364 2.637s4.665-.937 6.364-2.637C21.063 16.665 22 14.405 22 12s-.937-4.665-2.637-6.364a8.938 8.938 0 0 0-6.364-2.637z'></path>
    </svg>
);
export const LogoutIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='m2 12 5 4v-3h9v-2H7V8z'></path>
        <path d='M13.001 2.999a8.938 8.938 0 0 0-6.364 2.637L8.051 7.05c1.322-1.322 3.08-2.051 4.95-2.051s3.628.729 4.95 2.051 2.051 3.08 2.051 4.95-.729 3.628-2.051 4.95-3.08 2.051-4.95 2.051-3.628-.729-4.95-2.051l-1.414 1.414c1.699 1.7 3.959 2.637 6.364 2.637s4.665-.937 6.364-2.637c1.7-1.699 2.637-3.959 2.637-6.364s-.937-4.665-2.637-6.364a8.938 8.938 0 0 0-6.364-2.637z'></path>
    </svg>
);
export const MessageIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <circle cx='9.5' cy='9.5' r='1.5'></circle>
        <circle cx='14.5' cy='9.5' r='1.5'></circle>
        <path d='M12 2C6.486 2 2 5.589 2 10c0 2.908 1.897 5.515 5 6.934V22l5.34-4.004C17.697 17.852 22 14.32 22 10c0-4.411-4.486-8-10-8zm0 14h-.333L9 18v-2.417l-.641-.247C5.671 14.301 4 12.256 4 10c0-3.309 3.589-6 8-6s8 2.691 8 6-3.589 6-8 6z'></path>
    </svg>
);
export const MenuIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z'></path>
    </svg>
);
export const NotificationsIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z'></path>
    </svg>
);
export const PoliceCheckBadge = () => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 25'>
        <path
            fill='#D5E2EB'
            d='M9.94.76a3.17 3.17 0 0 1 4.12 0l.016.014C15.606 2.111 17.646 3 19 3a3 3 0 0 1 3 3v7c0 3.028-1.083 5.402-2.838 7.202-1.7 1.744-3.94 2.862-6.164 3.636l-.016.006a3 3 0 0 1-1.982-.02c-2.224-.77-4.462-1.883-6.16-3.622C3.083 18.402 2 16.028 2 13V6a3 3 0 0 1 3-3C6.353 3 8.404 2.102 9.924.774L9.928.77l.013-.01Zm1.394 22.122a2 2 0 0 0 1.327.015l.008-.003c2.152-.75 4.23-1.802 5.777-3.39C20.021 17.89 21 15.764 21 13V6a2 2 0 0 0-2-2c-1.677 0-3.942-1.04-5.582-2.473l-.008-.007a2.17 2.17 0 0 0-2.82 0l-.008.007C8.952 2.95 6.676 4 5 4a2 2 0 0 0-2 2v7c0 2.764.98 4.889 2.556 6.504 1.547 1.584 3.624 2.633 5.778 3.378Z'
        />
        <g filter='url(#a)'>
            <path
                fill='url(#b)'
                d='M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1v7Z'
            />
        </g>
        <path
            fill='#fff'
            d='M15.53 9.22a.75.75 0 0 1 0 1.06l-4.125 4.125a.75.75 0 0 1-1.06 0L8.47 12.53a.75.75 0 0 1 1.06-1.06l1.345 1.344L14.47 9.22a.75.75 0 0 1 1.06 0Z'
        />
        <path
            fill='#fff'
            d='M11.892 3.038C10.042 4.652 7.322 6 5 6v7c0 2.236.77 3.861 1.987 5.106 1.243 1.274 2.997 2.194 5.01 2.889l.022.008c2.005-.7 3.754-1.622 4.995-2.895C18.229 16.861 19 15.236 19 13V6c-2.32 0-5.032-1.338-6.892-2.962a.17.17 0 0 0-.216 0ZM10.59 1.52a2.17 2.17 0 0 1 2.82 0l.008.007C15.058 2.961 17.323 4 19 4a2 2 0 0 1 2 2v7c0 2.764-.98 4.889-2.554 6.504-1.547 1.588-3.625 2.64-5.777 3.39l-.008.003a2 2 0 0 1-1.327-.015c-2.154-.745-4.231-1.794-5.778-3.378C3.979 17.889 3 15.764 3 13V6a2 2 0 0 1 2-2c1.676 0 3.952-1.049 5.582-2.473l.008-.007Z'
        />
        <defs>
            <linearGradient id='b' x1='20' x2='4' y1='2' y2='22.5' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#04227B' />
                <stop offset='1' stopColor='#073EE1' />
            </linearGradient>
            <filter
                id='a'
                width='20'
                height='24.003'
                x='2'
                y='1'
                colorInterpolationFilters='sRGB'
                filterUnits='userSpaceOnUse'
            >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
                <feOffset dy='1' />
                <feGaussianBlur stdDeviation='1' />
                <feComposite in2='hardAlpha' operator='out' />
                <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0' />
                <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1161_1593' />
                <feBlend in='SourceGraphic' in2='effect1_dropShadow_1161_1593' result='shape' />
            </filter>
        </defs>
    </svg>
);
export const ProfileIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z'></path>
    </svg>
);
export const ProfileCalendarIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z'></path>
        <path d='M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z'></path>
    </svg>
);
export const ProfileDashboardIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm5 2h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1zm1-6h4v4h-4V5zM3 20a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6zm2-5h4v4H5v-4zm8 5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6zm2-5h4v4h-4v-4z'></path>
    </svg>
);
export const ProfileFavoritesIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M12 4.595a5.904 5.904 0 0 0-3.996-1.558 5.942 5.942 0 0 0-4.213 1.758c-2.353 2.363-2.352 6.059.002 8.412l7.332 7.332c.17.299.498.492.875.492a.99.99 0 0 0 .792-.409l7.415-7.415c2.354-2.354 2.354-6.049-.002-8.416a5.938 5.938 0 0 0-4.209-1.754A5.906 5.906 0 0 0 12 4.595zm6.791 1.61c1.563 1.571 1.564 4.025.002 5.588L12 18.586l-6.793-6.793c-1.562-1.563-1.561-4.017-.002-5.584.76-.756 1.754-1.172 2.799-1.172s2.035.416 2.789 1.17l.5.5a.999.999 0 0 0 1.414 0l.5-.5c1.512-1.509 4.074-1.505 5.584-.002z'></path>
    </svg>
);
export const ProfileFeedbackIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='m6.516 14.323-1.49 6.452a.998.998 0 0 0 1.529 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082a1 1 0 0 0-.59-1.74l-5.701-.454-2.467-5.461a.998.998 0 0 0-1.822 0L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.214 4.107zm2.853-4.326a.998.998 0 0 0 .832-.586L12 5.43l1.799 3.981a.998.998 0 0 0 .832.586l3.972.315-3.271 2.944c-.284.256-.397.65-.293 1.018l1.253 4.385-3.736-2.491a.995.995 0 0 0-1.109 0l-3.904 2.603 1.05-4.546a1 1 0 0 0-.276-.94l-3.038-2.962 4.09-.326z'></path>
    </svg>
);
export const ExternalIcon = ({ style }: { style?: CSSProperties | undefined }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        style={{ fill: 'currentColor', ...style }}
    >
        <path d='M5 7a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-6a1 1 0 1 1 2 0v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h6a1 1 0 1 1 0 2H5ZM14 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V4h-5a1 1 0 0 1-1-1Z' />
        <path d='M21.707 2.293a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414-1.414l11-11a1 1 0 0 1 1.414 0Z' />
    </svg>
);
export const RegisterIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='m7 17.013 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58zM9 13.417l6.03-5.973 1.586 1.586-6.029 5.971L9 15.006v-1.589z'></path>
        <path d='M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z'></path>
    </svg>
);
export const RightIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z'></path>
    </svg>
);
export const SearchIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z'></path>
    </svg>
);
export const SendIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
        <path
            fill='currentColor'
            d='M2.337 2.252a1 1 0 0 1 1.091-.156l19 9a1 1 0 0 1 0 1.808l-19 9a1 1 0 0 1-1.377-1.22L4.946 12 2.051 3.316a1 1 0 0 1 .286-1.064ZM4.69 4.907l2.259 6.777a1 1 0 0 1 0 .632l-2.26 6.777L19.665 12 4.69 4.907Z'
        />
        <path fill='currentColor' d='M5 12a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Z' />
    </svg>
);
export const ServicesIcon = ({ style }: { style?: CSSProperties | undefined }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        fill='none'
        style={{ fill: 'currentColor', ...style }}
    >
        <path
            fill='currentColor'
            d='M21.884 7.059 19.66 2.614A1.112 1.112 0 0 0 18.667 2h-5.555c-.421 0-.806.238-.995.614l-1.914 3.83H5.334c-.421 0-.806.238-.994.615l-2.223 4.444a1.111 1.111 0 0 0 0 .995l2.223 4.444c.188.376.573.614.994.614h4.869l1.915 3.83a1.11 1.11 0 0 0 .994.614h5.555c.421 0 .805-.238.994-.614l2.223-4.445a1.112 1.112 0 0 0 0-.994L19.909 12l1.973-3.948a1.112 1.112 0 0 0 .002-.993Zm-8.086-2.837h4.182l1.667 3.334-1.667 3.333h-4.182l-1.666-3.333 1.666-3.334ZM4.354 12l1.667-3.333h4.182L11.869 12l-1.666 3.333H6.02L4.354 12Zm13.626 7.778h-4.182l-1.666-3.334 1.666-3.333h4.182l1.667 3.333-1.667 3.334Z'
        />
    </svg>
);

export const SitterApplicationIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' focusable='false'>
        <path
            d='M21 6H19L17.73 4.73C17.2707 4.27023 16.6498 4.00825 16 4H13.5C13.3599 3.4388 13.0394 2.93913 12.5878 2.57779C12.1361 2.21646 11.5783 2.01346 11 2V8.36C11.0514 9.36993 11.4506 10.331 12.13 11.08C13.2486 12.1553 14.7116 12.8 16.26 12.9L19.71 11.52C20.1148 11.3575 20.4793 11.1087 20.7781 10.7909C21.0768 10.4731 21.3027 10.094 21.44 9.68L22 8.15C22.0152 8.04722 22.0152 7.94278 22 7.84V7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6ZM16 8C15.8022 8 15.6089 7.94135 15.4444 7.83147C15.28 7.72159 15.1518 7.56541 15.0761 7.38268C15.0004 7.19996 14.9806 6.99889 15.0192 6.80491C15.0578 6.61093 15.153 6.43275 15.2929 6.29289C15.4327 6.15304 15.6109 6.0578 15.8049 6.01921C15.9989 5.98063 16.2 6.00043 16.3827 6.07612C16.5654 6.15181 16.7216 6.27998 16.8315 6.44443C16.9414 6.60888 17 6.80222 17 7C17 7.26522 16.8946 7.51957 16.7071 7.70711C16.5196 7.89464 16.2652 8 16 8Z'
            fill='currentColor'
        ></path>
        <path
            d='M11.38 11.74C10.6824 10.976 10.2266 10.0226 10.07 9H6C5.73278 9.01768 5.46487 8.97806 5.21421 8.88381C4.96354 8.78955 4.7359 8.64283 4.54654 8.45346C4.35717 8.2641 4.21045 8.03646 4.11619 7.78579C4.02194 7.53513 3.98232 7.26722 4 7C4 6.73478 3.89464 6.48043 3.70711 6.29289C3.51957 6.10536 3.26522 6 3 6C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7C2.01005 7.69397 2.17399 8.37706 2.48 9C2.81735 9.65412 3.34811 10.1884 4 10.53V22H7V17H13V22H16V13.87C14.2555 13.7326 12.6174 12.9773 11.38 11.74Z'
            fill='currentColor'
        ></path>
    </svg>
);

export const UserIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style={{ fill: 'currentColor' }}>
        <path d='M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z'></path>
        <path d='M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z'></path>
    </svg>
);
export const IcPickUpDropOff = ({ style }: { style?: CSSProperties | undefined }) => (
    <svg viewBox='0 0 24 24' className='w-lg h-lg' style={{ fill: 'currentColor', ...style }}>
        <path
            fill='currentColor'
            d='M8.41 4h7.245a3 3 0 0 1 2.776 1.936l.928 2.29.933-.933a1 1 0 1 1 1.415 1.415l-2 2a1 1 0 0 1-1.634-.332l-1.5-3.7A1 1 0 0 0 15.64 6H8.377a1 1 0 0 0-.952.629l-1.497 3.743a1 1 0 0 1-1.636.336l-2-2a1 1 0 1 1 1.415-1.415l.927.928.934-2.335A3 3 0 0 1 8.41 4.001ZM6 14a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM16 14a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H17a1 1 0 0 1-1-1Z'
        />
        <path
            fill='currentColor'
            d='M5 11a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H5Zm-3 1a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-4Z'
        />
        <path
            fill='currentColor'
            d='M5 17a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1ZM19 17a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1Z'
        />
    </svg>
);
export const IcTravelExpense = ({ style }: { style?: CSSProperties | undefined }) => (
    <svg viewBox='0 0 24 24' className='w-lg h-lg' style={{ fill: 'currentColor', ...style }}>
        <path
            fill='currentColor'
            d='M4 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H4ZM1 9a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V9Z'
        />
        <path
            fill='currentColor'
            d='M7.879 2.879A3 3 0 0 1 10 2h4a3 3 0 0 1 3 3v16a1 1 0 1 1-2 0V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v16a1 1 0 1 1-2 0V5a3 3 0 0 1 .879-2.121Z'
        />
    </svg>
);
export const IcUnknown = ({ style }: { style?: CSSProperties | undefined }) => (
    <svg viewBox='0 0 24 24' className='w-lg h-lg' style={{ fill: 'currentColor', ...style }}>
        <path
            fill='currentColor'
            d='M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Z'
        />
        <path
            fill='currentColor'
            d='M12.258 8.024a2 2 0 0 0-2.224 1.308 1 1 0 0 1-1.887-.664 4 4 0 0 1 7.773 1.333c0 1.53-1.135 2.54-1.945 3.081a8.045 8.045 0 0 1-1.686.848l-.035.013-.011.003-.004.002h-.001s-.002 0-.318-.948l.316.949a1 1 0 0 1-.633-1.897h-.001l.017-.006a5.763 5.763 0 0 0 .364-.147c.247-.11.568-.272.882-.481.69-.46 1.055-.949 1.055-1.418v-.002a2 2 0 0 0-1.662-1.974ZM11 17a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Z'
        />
    </svg>
);
